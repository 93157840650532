import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ILogin } from "../../shared/model/login/login.model";
import { HttpStatusCode, ResponseModel } from "../../shared/responsemodel";
import { ApiDataService } from "../../shared/services/apidata.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component.html",
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginwithotpForm: FormGroup;
  forgetPasswordForm: FormGroup;
  submitted = false;
  submittedForget = false;
  isSaveDisabled = false;
  loading: boolean = false;
  isLive = true;
  info: any;
  isLaunch = false;

  pageRoleList: any[];

  public data: any;

  username: string;

  loginfailerror = false;
  loginOtpfailerror = false;
  otpfailerror = false;
  invalidUsernameError = false;
  showloder = false;
  showform = "login";
  inputType = "password";

  showloderforotp = false;
  showLoaderForVerify = false;
  showLoaderForSetPassword = false;

  isForgetOTPSent = false;
  showOtpBox = false;
  showSuccessMessage = false;

  setpasswordDisabled = true;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiservice: ApiDataService,
    private toastr: ToastrService,
    private toastrService: ToastrService,
  ) {
    this.loginForm = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });

    this.loginwithotpForm = this.fb.group({
      otp: ["", [Validators.required]],
    });

    this.forgetPasswordForm = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
      otp: ["", [Validators.required]],
    });
  }

  ngOnInit() {
    if (localStorage.getItem("info")) {
      this.router.navigateByUrl("/dashboard");
    }
  }
  get f() {
    return this.loginForm.controls;
  }

  get fp() {
    return this.forgetPasswordForm.controls;
  }

  public login() {
    this.submitted = true;
    this.loginfailerror = false;
    this.loginOtpfailerror = false;

    this.loginForm = this.fb.group({
      username: [this.loginForm.value.username, [Validators.required]],
      password: [this.loginForm.value.password, [Validators.required]],
    });

    if (this.loginForm.valid) {
      this.isSaveDisabled = true;

      const data: ILogin = {
        userName: this.loginForm.value.username,
        password: this.loginForm.value.password,
        loginfrom: 1,
      };
      this.showloder = true;
      const url = `AdminUser/login`;
      this.apiservice
        .postData(url, data)
        .subscribe((response: ResponseModel<any>) => {
          this.showloder = false;
          if (response.status === HttpStatusCode.OK) {
            this.data = response.data;
            this.isSaveDisabled = false;

            this.loginfailerror = false;
            localStorage.setItem("info", JSON.stringify(response.data));

            this.pageRole();
            this.router.navigateByUrl("/dashboard");
          } else {
            this.loginfailerror = true;
            this.isSaveDisabled = false;
          }
        });
    }
  }

  public getOtpForForgetpassword() {
    if (this.forgetPasswordForm.controls.username.valid) { 
      const url = `AdminUser/forgetPassword?username=${this.forgetPasswordForm.value.username}`;
      this.apiservice.getData(url).subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.isForgetOTPSent = true;
          this.showOtpBox = true;

          var interval = setInterval(() => {
            this.isForgetOTPSent = false;
            clearInterval(interval);
          }, 3000);
        } else {
          this.isForgetOTPSent = false;
        }
      });
    }
  }

  public setPassword() {
    this.submittedForget = true;
    if (this.forgetPasswordForm.valid) {
      const data = {
        userName: this.forgetPasswordForm.value.username,
        newPassword: this.forgetPasswordForm.value.password,
        otp: this.forgetPasswordForm.value.otp,
        loginfrom: 1,
      };

      const url = `AdminUser/setpassLogin`;

      this.apiservice.postData(url, data).subscribe(
        (response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.forgetPasswordForm.reset();
            this.isForgetOTPSent = false;
            this.showOtpBox = false;
            // this.showSuccessMessage = true;
            this.submitted = false;
            this.showToast('success', 'Password Update Successfully');
            var interval = setInterval(() => {
              this.showSuccessMessage = false;
              this.showform = 'login';
              clearInterval(interval);
            }, 2000)
          } else {
            this.submittedForget = false;
          }
        }
      )
    }
  }

  showToast(status, message) {
    this.toastr.success(`${message}`, `${status}`);
  }


  public pageRole() {
		const url = `PageAccess/GetPageAccessRolewise?pageSize=0&pageNumber=0`;
    this.apiservice.getData(url).subscribe
			((response: ResponseModel<any[]>) => {
				if (response.status === HttpStatusCode.OK) {
					this.pageRoleList = response.data;
					localStorage.setItem('roleInfo', JSON.stringify(response.data));
					// this.router.navigateByUrl('/dashboard/fpdashboard');
				}
			});
	}
}
